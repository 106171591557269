import {Link, useRouteError} from 'react-router-dom';
import cl from './errorPage.module.scss';

interface IRouterError {
    error: {
        message: string
    };
    status: number;
    statusText: string;
}

const ErrorPage = () => {
    const error = useRouteError() as IRouterError;
    console.error(error);
    return (
        <div className={cl.errorPage}>
            <h2>
                Вы перешли на несущесвующую страницу <br/>
                Ошибка: {error.statusText} <br/>
                Статус ошибки: {error.status} <br/>
                Сообщение: {error.error.message} <br/>
            </h2>
            <Link className={cl.link} to="/">Перейти на главную</Link>
        </div>
    );
};

export default ErrorPage;